/*******************************
         Site Overrides
*******************************/

.ui.wide.sidebar:not(.visible) {
  width: 0px;
}

.ui.wide.sidebar {
  width: 26em !important;
}