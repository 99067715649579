/*******************************
    User Variable Overrides
*******************************/

.ui.list > .item .header {
  font-weight: normal;
}

.ui.list > .item .content p {
  display: inline;
  font-weight: normal;
  margin-left: 0.8em;
  opacity: 0.8;
}