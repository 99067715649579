/*******************************
         Site Overrides
*******************************/

.ui.text.fixed.menu {
  padding-right: 2em;
  padding-left: 2em;
}

.ui.menu.fixed {
  background: #FFFFFF;
  border: 0px;
  letter-spacing: 0.1em;
  max-width: 100vw;
}

.ui.menu.fixed .item, .ui.menu .active.item {
  color: #020a3a !important;
}

.ui.menu.fixed .ui.header {
  color: #FFFFFF;
}

.ui.menu.fixed .item:hover, .ui.menu .active.item:hover {
  color: #020a3a !important;
}

.ui.menu.fixed .item.disabled, .ui.menu .item.disabled:hover {
  color: #FFFFFF !important;
  opacity: 0.5;
}

.ui.menu.fixed .active.item {
 font-weight: bold !important;
}

.ui.fixed.menu:not(.tabular) {
  border: 0px;
  border-bottom: 0.1em solid #c1cbff;
  margin: 0px;
}

.right.menu .item {
  padding-left: 1.5em !important;
  padding-right: 0px !important;
}

.ui.menu.fixed {
  z-index: 104;
  height: 42px;
}

.ui.menu {
  border: none;
}

.ui.menu.fixed .icon {
  cursor: pointer;
}