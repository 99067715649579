/*******************************
         Site Overrides
*******************************/

@font-face {
  font-family: karla;
  src:url('../fonts/karla/Karla-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: none;
}

@font-face {
  font-family: karla;
  src:url('../fonts/karla/Karla-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: none;
}


@font-face {
  font-family: rubik;
  src:url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: none;
}

@font-face {
  font-family: rubik;
  src:url('../fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: none;
}

@font-face {
  font-family: rubik;
  src:url('../fonts/rubik/Rubik-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: metropolis;
  src:url('../fonts/metropolis/Metropolis-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: none;
}

@font-face {
  font-family: metropolis;
  src:url('../fonts/metropolis/Metropolis-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: none;
}

@font-face {
  font-family: metropolis;
  src:url('../fonts/metropolis/Metropolis-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: metropolis;
  src:url('../fonts/metropolis/Metropolis-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: none;
}