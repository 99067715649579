/*******************************
         Site Overrides
*******************************/

.ui.table tr.active {
  background-color: #f9fcff !important;
}

.ui.table.not-in-progress tr:hover:not(.active) {
  cursor: pointer;
  background-color: #f9fcff !important;
}

.ui.table {
  box-shadow: 0 0 8px 0 rgba(95, 101, 121, 0.21);
}

.ui.table th {
  background-color: #FFFFFF !important;
  color: #bebebe !important;
  font-size: 0.9em;
  padding-top: 0.4em !important;
  padding-bottom: 0.4em !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.18);
}

.ui.table .arrow.right.icon:hover {
  cursor: pointer;
}

.ui.table td {
  white-space: nowrap;
}