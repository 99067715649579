/*******************************
         Site Overrides
*******************************/

.ui.header {
  color: #064894;
}

h1.ui.header.medium {
  font-family: Metropolis;
  color: #009fee;
  font-size: 24px;
}

h2.ui.header.medium {
  font-size: 16px;
}