/*******************************
    User Variable Overrides
*******************************/

.ui.card {
  box-shadow: 0 0 8px 0 rgba(95, 101, 121, 0.21) !important;
  font-size: 13px !important;
  text-align: center;
  line-height: 1.2em;
  width: 14em !important;
}

.ui.card .image {
  overflow-y: hidden;
  height: 8em;
}

.ui.card .divider {
  border-top: 2px solid black;
  margin: 0.5rem 0rem !important;
}

.ui.card .content:not(.extra) {
  padding: 0.6em !important;
}