/*******************************
         Site Overrides
*******************************/

.three.wide.column {
  display: flex !important;
  align-items: flex-end;
}

.ui.button.margin-left {
    margin: 0px !important;
}