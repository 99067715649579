/*******************************
         Site Overrides
*******************************/

.ui.button {
  background:linear-gradient(97deg, #064894 -105%, #009fec 104%);
  color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(13, 0, 82, 0.27);
}

.ui.button:hover {
  background:linear-gradient(97deg, #036FBC -105%, #036FBC 104%);
  color: #FFFFFF;
}

.ui.button:active {
  color: #FFFFFF;
}

.ui.button.disabled {
  opacity: 1 !important;
}

.ui.button.disabled:not(.loading) {
  background-color: #f4f4f4 !important;
}